<script lang="ts">
  import { t } from 'svelte-i18n';
  import type { Repayment } from '../../types/mpay.types';
  import { PaymentStatus } from '../constants';
  import { isTransactionPending } from '../utils/date';
  import { getFormattedTimestamp } from '../utils/formatters';
  import Badge from './Badge.svelte';
  import Icon from './Icon.svelte';
  import ListInvoiceAmount from './ListInvoiceAmount.svelte';

  export let repayment: Repayment;
  $: formattedDateTime = getFormattedTimestamp(repayment.date);
  $: isPending = isTransactionPending(repayment.date);
</script>

<li
  class={`border-b border-primary-tint90 px-5 last:border-0 ${isPending ? 'text-semantic-disabled' : 'text-primary-base'}`}
  data-cy="repayment-list-item"
>
  <div
    class="grid grid-cols-[40px_minmax(0,1fr)_80px] items-center gap-3 pb-[15px] pt-[17px]"
  >
    <div
      class={`flex h-[40px] w-[40px] items-center justify-center rounded-lg p-2 ${isPending ? 'bg-primary-tint90 text-semantic-disabled' : 'bg-semantic-inverted text-semantic-neutral shadow-small'}`}
    >
      <Icon iconName={'Logout'} />
    </div>

    <div class="w-max-1/2 flex w-full flex-col">
      <div class="flex items-center">
        <time
          datetime={formattedDateTime}
          class="mr-2 text-android-title-medium ios:text-ios-headline ios:font-semibold"
        >
          {formattedDateTime}
        </time>

        {#if isPending}
          <Badge type={PaymentStatus.PENDING} />
        {/if}
      </div>

      <div class="flex">
        <p class="mr-1 truncate text-android-body-small ios:text-ios-footnote">
          {$t('payment_type.a2a_repayment')}
        </p>
      </div>
    </div>

    <div class="flex h-full justify-end">
      <ListInvoiceAmount
        isMain
        amount={-repayment.amount}
        type={isPending ? 'pending' : 'voucher'}
      />
    </div>
  </div>
</li>
