import type {
  Invoice,
  MonthlyInvoices,
  MonthlyTransactions,
  Repayment,
  Transactions,
} from '../../types/mpay.types';
import { PayloadEntryType } from '../constants';
import { getMonthYearFromDateTranslation } from './translations';

export function addTypeToInvoices(invoices: Invoice[]): Invoice[] {
  return [
    ...invoices.map((i) => ({
      ...i,
      type: PayloadEntryType.INVOICE,
    })),
  ];
}

export function getInvoiceById(
  data: MonthlyInvoices | MonthlyTransactions,
  id: string
): Invoice | null {
  let foundInvoice = null;

  Object.keys(data).forEach((month) => {
    const currResult = data[month].find(
      (item) => (isInvoiceType(item) ? item.number : item.timestamp) === id
    );

    if (currResult) {
      foundInvoice = currResult;
    }
  });

  return foundInvoice;
}

export function getMergedInvoices(
  oldVal: MonthlyInvoices,
  newVal: MonthlyInvoices
): MonthlyInvoices {
  if (Object.keys(oldVal).length > 0) {
    Object.keys(newVal).forEach((key) => {
      if (oldVal[key]) {
        const existingInvoices = new Set(
          oldVal[key].map((invoice) => invoice.number)
        );

        // filter out invoices with duplicate numbers
        const uniqueNewInvoices = newVal[key].filter(
          (invoice) => !existingInvoices.has(invoice.number)
        );

        oldVal[key].push(...uniqueNewInvoices);
      } else {
        oldVal[key] = newVal[key];
      }
    });
    return oldVal;
  } else {
    return newVal;
  }
}

export function getMergedTransactionsData(
  oldVal: MonthlyTransactions,
  newVal: MonthlyTransactions
): MonthlyTransactions {
  if (Object.keys(oldVal).length === 0) return newVal;

  Object.entries(newVal).forEach(([key, newItems]) => {
    if (oldVal[key]) {
      const existingKeys = new Set(
        oldVal[key].map((item) =>
          isInvoiceType(item) ? item.number : item.timestamp
        )
      );

      oldVal[key].push(
        ...newItems.filter((item) =>
          isInvoiceType(item)
            ? !existingKeys.has(item.number)
            : !existingKeys.has(item.timestamp)
        )
      );
    } else {
      oldVal[key] = newItems;
    }
  });

  return oldVal;
}

export function getSortedInvoicesByMonthYear(
  invoices: Invoice[]
): MonthlyInvoices {
  return invoices.reduce((acc: MonthlyInvoices, curr) => {
    const invMonthYear = getMonthYearFromDateTranslation(curr.date);

    acc[invMonthYear] = [...(acc[invMonthYear] || []), curr];

    return acc;
  }, {});
}

export function getTransactions(
  invoices: Invoice[],
  payments: Repayment[]
): Transactions {
  return [
    ...addTypeToInvoices(invoices),
    ...payments.map((p) => ({
      ...p,
      date: new Date(p.timestamp).toISOString(),
      type: PayloadEntryType.REPAYMENT,
    })),
  ].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
}

export function isInvoiceType(data: Invoice | Repayment): data is Invoice {
  return data.type === PayloadEntryType.INVOICE;
}

export function isRepaymentType(data: Invoice | Repayment): data is Repayment {
  return data.type === PayloadEntryType.REPAYMENT;
}
