import type { MonthlyTransactions, Transactions } from '../../types/mpay.types';
import { CreditSettleFrequency } from '../constants';
import { getFormattedDate } from './formatters';

function formatRange(start: Date, end: Date) {
  return `${getFormattedDate(start)} - ${getFormattedDate(end)}`;
}

function get24To23Month(date: Date) {
  let start;
  let end;
  const CUTOFF_DAY = 24;
  const { month, year } = getDates(date);

  if (date.getDate() >= CUTOFF_DAY) {
    start = new Date(year, month, CUTOFF_DAY);
    end = new Date(year, month + 1, CUTOFF_DAY - 1);
  } else {
    start = new Date(year, month - 1, CUTOFF_DAY);
    end = new Date(year, month, CUTOFF_DAY - 1);
  }

  return formatRange(start, end);
}

function getDates(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return { day, month, year };
}

function getHalfMonths(date: Date) {
  const CUTOFF_DAY = 15;
  const { day, month, year } = getDates(date);
  const lastDatOfTheMonth = new Date(year, month + 1, 0).getDate();

  const start = new Date(year, month, day <= CUTOFF_DAY ? 1 : 16);
  const end = new Date(
    year,
    month,
    day <= CUTOFF_DAY ? CUTOFF_DAY : lastDatOfTheMonth
  );

  return formatRange(start, end);
}

function getMonth(date: Date) {
  const { month, year } = getDates(date);

  const start = new Date(year, month, 1);
  const end = new Date(year, month + 1, 0);

  return formatRange(start, end);
}

export function groupInvoices(
  data: Transactions,
  groupBy: CreditSettleFrequency
) {
  const groups: MonthlyTransactions = {};

  data.forEach((entry) => {
    const date = new Date(entry.date);

    let groupKey;

    switch (groupBy) {
      case CreditSettleFrequency.MEDIU14:
      case CreditSettleFrequency.MEDIU21:
      case CreditSettleFrequency.MEDIU26:
        groupKey = getHalfMonths(date);
        break;
      case CreditSettleFrequency.MEDIU20:
        groupKey = get24To23Month(date);
        break;
      case CreditSettleFrequency.FIX15:
      case CreditSettleFrequency.FIX30:
      case CreditSettleFrequency.MEDIU30:
      case CreditSettleFrequency.MEDIU45:
      default:
        // all the other possible "FIX XX" will default to a month
        groupKey = getMonth(date);
        break;
    }

    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }

    groups[groupKey].push(entry);
  });

  return groups;
}
