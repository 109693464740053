import { jwtDecode } from 'jwt-decode';
import { navigate } from 'svelte-routing';
import { IDAM_AUTH } from '../../env';
import { routes } from '../../routes';
import { settingsStore } from '../../store/settingsStore';
import { ACCOUNT_ID_ITEM, TOKEN_ITEM } from '../constants';
import { callNative } from '../JSBridge/callNative';
import { isIosWebView } from './detectPlatform';
import { getAccountId, getToken } from './localStorage';

export const areCredentialsValid = (): boolean => {
  const token = getToken();
  const accountId = getAccountId();

  if (token && accountId) {
    const userData: { exp: number } = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    return userData.exp > currentTime;
  }

  return false;
};

const setCredentials = async (
  hash: string,
  accountIdFromNativeIos: string
): Promise<void> => {
  const params = new URLSearchParams(hash.substring(1)); // Remove the '#' from the URL

  const accountId =
    isIosWebView() && accountIdFromNativeIos
      ? accountIdFromNativeIos
      : params.get('state');
  const token = params.get('access_token');

  if (accountId && token) {
    settingsStore.setCredentials({ accountId, token });

    window.localStorage.setItem(ACCOUNT_ID_ITEM, accountId);
    window.localStorage.setItem(TOKEN_ITEM, token);
  } else {
    throw new Error();
  }
};

const setRepaymentStatus = (): void => {
  const authUrl = new URL(window.location.href);
  const repaymentStatusInPath =
    authUrl.pathname.match(
      /repayment-status\/(completed|failed|revoked)/
    )?.[1] || '';

  if (repaymentStatusInPath) {
    settingsStore.setRepaymentStatus(repaymentStatusInPath);
  }
};

async function getAccountIdFromNativeIos() {
  try {
    return (await callNative('getAccountID')) || '';
  } catch {
    return '';
  }
}

const getIdamUrl = (
  accountId: string,
  repaymentStatusInParams: string
): string => {
  const nonce = crypto.randomUUID();
  const baseUrl = IDAM_AUTH.BASE_URL;
  const redirect_uri = `${window.location.origin}${
    repaymentStatusInParams
      ? `${routes.repaymentStatus.path}/${repaymentStatusInParams}`
      : window.location.pathname
  }`;

  const queryParams = {
    client_id: IDAM_AUTH.CLIENT_ID,
    nonce,
    realm_id: IDAM_AUTH.REALM_ID,
    redirect_uri,
    response_type: IDAM_AUTH.RESPONSE_TYPE,
    scope: IDAM_AUTH.SCOPE,
    state: `${accountId}`,
    user_type: IDAM_AUTH.USER_TYPE,
  };

  return `${baseUrl}?${new URLSearchParams(queryParams)}`;
};

// Authenticates the logged in user in an in-app browser flow based on credentials passed from the app
export const authenticateUser = async (): Promise<void> => {
  const accountIdFromNativeIos = await getAccountIdFromNativeIos();

  return new Promise<void>((resolve, reject) => {
    const authUrl = new URL(window.location.href);
    const searchParams = authUrl.searchParams;

    const validRepaymentStatuses = ['completed', 'failed', 'revoked'];
    const repaymentStatusInParams =
      (authUrl.pathname.includes('mcredit') &&
        validRepaymentStatuses.find(
          (status) => status === searchParams.get('status')
        )) ||
      '';

    // check for authentication errors in the URL
    if (searchParams.has('error')) {
      reject(
        'Authentication error in url ' + searchParams.get('error_description')
      );
      return;
    }

    // check if local storage credentials are valid
    if (areCredentialsValid()) {
      settingsStore.setCredentials({
        accountId: getAccountId()!,
        token: getToken()!,
      });

      if (repaymentStatusInParams) {
        settingsStore.setRepaymentStatus(repaymentStatusInParams);
        navigate(`${routes.repaymentStatus.path}/${repaymentStatusInParams}`);
      } else {
        navigate(window.location.pathname);
      }

      resolve();
      return;
    }

    // handle search parameters in the URL before IDAM redirect
    if (searchParams.toString()) {
      const accountId = [...searchParams.entries()]
        .find(([key]) => key.toLowerCase() === ACCOUNT_ID_ITEM.toLowerCase())
        ?.at(1);

      if (accountId) {
        // pass through accountId – it will be returned by IDAM in hash
        window.location.replace(getIdamUrl(accountId, repaymentStatusInParams));
        return;
      }
      reject('Missing authentication credentials');
    }

    // handle url hash after IDAM redirect
    if (authUrl.hash) {
      try {
        setCredentials(authUrl.hash, accountIdFromNativeIos);
        setRepaymentStatus();
        resolve();
      } catch {
        reject('Missing access token or state in auth url');
      }
    }
    reject('Authentication failed');
  });
};
