import { get, writable } from 'svelte/store';
import type { Pagination } from '../types/globals.types';
import type {
  CreditLimit,
  CreditSettle,
  MCreditSummary,
  MonthlyTransactions,
} from '../types/mpay.types';
import { MPAY_API_BASE_URI } from '../env';
import { CreditSettleFrequency, MPayApi } from '../lib/constants';
import { sendRequest } from '../lib/utils/fetch';
import { dialogStore } from './dialogStore';
import { settingsStore } from './settingsStore';

const initialState: {
  creditLimit: CreditLimit;
  creditSettle: CreditSettle;
  currentPage: number;
  mCreditSummary: MCreditSummary;
  pagination: Pagination;
  transactions: MonthlyTransactions;
} = {
  creditLimit: {
    availableCreditLimit: 0,
    currencyCode: '',
    exhaustedCreditLimit: 0,
    grantedCreditLimit: 0,
  },
  creditSettle: {
    creditSettleFrequencyCd: '',
    creditSettlePeriodCd: '',
    creditSettleTypeCd: '',
    creditTpContract: CreditSettleFrequency.EMPTY,
  },
  currentPage: 1,
  mCreditSummary: {
    currencyCode: '',
    dueAmount: 0,
    nextDueDate: '',
    overdueAmount: 0,
    totalMRIRequestCount: 0, // this is a tracking metric only
  },
  pagination: {
    currentPage: 0,
    total: 0,
    totalPages: 0,
  },
  transactions: {},
};

const createMCreditStore = () => {
  const { subscribe, update } = writable(initialState);

  return {
    setCreditLimit: (value: CreditLimit) =>
      update((state) => ({
        ...state,
        creditLimit: value,
      })),
    setCreditSettle: (value: CreditSettle) =>
      update((state) => ({
        ...state,
        creditSettle: value,
      })),
    setCurrentPage: (value: number) =>
      update((state) => ({
        ...state,
        currentPage: value,
      })),
    setMCreditSummary: (value: MCreditSummary) =>
      update((state) => ({
        ...state,
        mCreditSummary: value,
      })),
    setTransactions: (data: {
      pagination: Pagination;
      transactions: MonthlyTransactions;
    }) =>
      update((state) => ({
        ...state,
        ...data,
      })),
    subscribe,
  };
};

export const mCreditStore = createMCreditStore();

export const fetchCreditSettleDetails = async () => {
  try {
    const settings = get(settingsStore);
    const creditSettleResponse = await sendRequest<CreditSettle>(
      `${MPAY_API_BASE_URI}/${MPayApi.CREDIT_SETTLE}/${settings.credentials.accountId}`
    );

    mCreditStore.setCreditSettle(creditSettleResponse);
  } catch {
    dialogStore.setRetryAction(fetchCreditSettleDetails);
  }
};
