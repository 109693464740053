<script lang="ts">
  import {
    type ComponentType,
    onDestroy,
    onMount,
    type SvelteComponent,
  } from 'svelte';
  import { t } from 'svelte-i18n';
  import { navigate } from 'svelte-routing';
  import type { Invoice } from '../../types/mpay.types';
  import { posthog } from '../../plugins/posthog';
  import { routes } from '../../routes';
  import { featureFlagsStore } from '../../store/featureFlagsStore';
  import { invoicesStore } from '../../store/invoicesStore';
  import { mCreditStore } from '../../store/mCreditStore';
  import { topBar } from '../../store/TopBarStore';
  import Icon from '../components/Icon.svelte';
  import InvoiceAndroidBottomButtons from '../components/InvoiceAndroidBottomButtons.svelte';
  import InvoiceHeaderContainer from '../components/InvoiceHeaderContainer.svelte';
  import InvoiceInBottomSheet from '../components/InvoiceInBottomSheet.svelte';
  import InvoiceNotice from '../components/InvoiceNotice.svelte';
  import InvoiceNumberAndDate from '../components/InvoiceNumberAndDate.svelte';
  import InvoicePageDots from '../components/InvoicePageDots.svelte';
  import InvoicePageIndicator from '../components/InvoicePageIndicator.svelte';
  import InvoicePaymentStatus from '../components/InvoicePaymentStatus.svelte';
  import { CustomerInvoiceType } from '../constants';
  import { showLoader } from '../JSBridge/loaderController';
  import Layout from '../layouts/main.svelte';
  import { Context, Target } from '../utils/analytics/keys';
  import { getInvoiceById } from '../utils/invoices';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';

  export let id = '';

  let invoice: Invoice | null = null;
  let href: string = '';
  let pdfComponent: ComponentType<SvelteComponent>;
  let isPdfComponentLoading = true;
  let isPdfFileLoaded = false;
  let currentPage = 1;
  let pageCount = 1;
  let hasPodInvoice: boolean;
  let isPodInvoiceOpened = false;

  posthog.capture('$pageview');
  showLoader();

  onMount(async () => {
    invoice =
      getInvoiceById($invoicesStore.invoices, id) ||
      getInvoiceById($mCreditStore.transactions, id);

    if (!invoice) return navigate(routes.home.path);

    hasPodInvoice = Boolean(
      invoice.customerInvoiceType === CustomerInvoiceType.COD &&
        invoice.podInvoice &&
        invoice.podInvoice.pdfLink
    );

    topBar.setButton({
      label: $t('buttons.details'),
      onClick: () => {
        trackEvents(EventsMapKeys.CTA_CLICK, {
          category: Context.Invoice,
          target: Target.PaymentDetails,
        });

        navigate(href);
      },
    });

    topBar.setContext(Context.Invoice);
    topBar.setShowBackButton(true);

    href = routes.invoiceDetails.path + '/' + invoice.number;

    try {
      const loadedComp = await import('../components/PdfViewer.svelte');
      pdfComponent = loadedComp.default;
    } catch {
      console.error('Failed to load PDF viewer component');
    } finally {
      isPdfComponentLoading = false;
      // not calling hideLoader(); to make the "loader" experience smooth. It will be hidden from the PdfViewer component
      // loader has a "safety" timeout of 15 seconds
    }
  });

  onDestroy(() => {
    topBar.reset();
  });

  const onPdfLoaded = (e: { detail: number }) => {
    pageCount = e.detail;
    isPdfFileLoaded = true;
  };

  const goBack = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: Context.Invoice,
      target: Target.BackToOverview,
    });
    navigate(routes.home.path);
  };

  const goToDetails = () => {
    trackEvents(EventsMapKeys.CTA_CLICK, {
      category: Context.Invoice,
      target: Target.PaymentDetails,
    });
    navigate(href);
  };

  const togglePodInvoice = () => {
    isPodInvoiceOpened = !isPodInvoiceOpened;
  };
</script>

<Layout>
  {#if invoice}
    {#if $featureFlagsStore.payment_status && invoice.mCreditPaymentStatus}
      <InvoicePaymentStatus
        paymentStatus={invoice.mCreditPaymentStatus}
        dueDate={invoice.dueDate}
      />
    {/if}

    <InvoiceNotice />

    <div class="mb-4 inline-flex w-full flex-col gap-4 px-4 pt-6">
      <InvoiceHeaderContainer>
        <div>
          <InvoiceNumberAndDate {invoice} />

          {#if hasPodInvoice}
            <button
              class="mt-2 inline-flex items-center justify-center gap-2 rounded text-android-title-small font-medium text-blue-base active:text-blue-tint20 ios:text-ios-callout ios:font-semibold"
              on:click={togglePodInvoice}
            >
              {$t('invoice.view_pod')}
              <Icon iconName="ArrowRight" size="24" />
            </button>
          {/if}
        </div>

        <InvoicePageIndicator {currentPage} {pageCount} />
      </InvoiceHeaderContainer>

      {#if isPdfComponentLoading}
        <div />
      {:else}
        <div class="bg-white">
          <svelte:component
            this={pdfComponent}
            href={invoice.pdfLink}
            on:load={onPdfLoaded}
            on:pageChange={(e) => (currentPage = e.detail)}
          />
        </div>
      {/if}

      {#if isPdfFileLoaded}
        <InvoicePageDots {pageCount} {currentPage} />

        <InvoiceAndroidBottomButtons {goBack} {goToDetails} />
      {/if}
    </div>

    {#if invoice.podInvoice}
      <InvoiceInBottomSheet
        invoice={invoice.podInvoice}
        isOpen={isPodInvoiceOpened}
        setIsOpen={togglePodInvoice}
      />
    {/if}
  {/if}
</Layout>
