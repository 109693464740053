import { DateRange } from '../constants';

// calculate date range for different time periods
export const calculateDateRange = (interval: DateRange) => {
  const today = new Date();
  const currentMonth = today.getMonth();
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3;

  switch (interval) {
    case DateRange.LAST_MONTH:
      return {
        endDate: new Date(today.getFullYear(), today.getMonth(), 0),
        startDate: new Date(today.getFullYear(), today.getMonth() - 1, 1),
      };
    case DateRange.LAST_YEAR:
      return {
        endDate: new Date(today.getFullYear() - 1, 11, 31),
        startDate: new Date(today.getFullYear() - 1, 0, 1),
      };
    case DateRange.THIS_AND_LAST_YEAR:
      return {
        endDate: new Date(today.getFullYear(), 11, 31),
        startDate: new Date(today.getFullYear() - 1, 0, 1),
      };
    case DateRange.THIS_MONTH:
      return {
        endDate: new Date(today.getFullYear(), today.getMonth() + 1, 0),
        startDate: new Date(today.getFullYear(), today.getMonth(), 1),
      };
    case DateRange.THIS_QUARTER:
      return {
        endDate: new Date(today.getFullYear(), quarterStartMonth + 3, 0),
        startDate: new Date(today.getFullYear(), quarterStartMonth, 1),
      };
    case DateRange.THIS_YEAR:
      return {
        endDate: new Date(today.getFullYear(), 11, 31),
        startDate: new Date(today.getFullYear(), 0, 1),
      };
    default:
      throw new Error('Invalid date range interval');
  }
};

// format date in query param format
export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};

// helper function to check if A2A Repayment transaction is in pending state
export function isTransactionPending(timestamp: string): boolean {
  const pendingHours = 72;
  const transaction = new Date(timestamp);
  const now = new Date();

  // convert all to ms and compare
  return now.getTime() - transaction.getTime() <= pendingHours * 1000 * 60 * 60;
}
