<script lang="ts">
  import type {
    MonthlyInvoices,
    MonthlyTransactions,
  } from '../../types/mpay.types';
  import { topBar } from '../../store/TopBarStore';
  import { getNextRoute } from '../utils/helpers';
  import { isInvoiceType, isRepaymentType } from '../utils/invoices';
  import { EventsMapKeys, trackEvents } from '../utils/tracking';
  import InvoicesListTimePeriod from './InvoicesListTimePeriod.svelte';
  import ListInvoice from './ListInvoice.svelte';
  import RepaymentListItem from './RepaymentListItem.svelte';

  export let transactions: MonthlyInvoices | MonthlyTransactions;
  export let index: number;
  export let id: string;
  export let timePeriod: string;
  export let isMcredit = false;
</script>

<InvoicesListTimePeriod
  {timePeriod}
  isFirstEntry={Boolean(index === 0 && transactions[timePeriod].length)}
  {isMcredit}
/>

<ul data-cy={id}>
  {#each transactions[timePeriod] as transaction}
    {#if isRepaymentType(transaction)}
      <RepaymentListItem repayment={transaction} />
    {:else if isInvoiceType(transaction)}
      <ListInvoice
        invoice={transaction}
        href={getNextRoute(transaction)}
        onClick={() =>
          trackEvents(EventsMapKeys.INVOICE_CLICK, $topBar.context)}
        isMcreditList={isMcredit}
      />
    {/if}
  {/each}
</ul>
