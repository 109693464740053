<script lang="ts">
  import { onMount } from 'svelte';
  import { t } from 'svelte-i18n';
  import type { Pagination } from '../../types/globals.types';
  import type {
    Invoice,
    Repayment,
    Transactions,
  } from '../../types/mpay.types';
  import { MPAY_API_BASE_URI } from '../../env';
  import { dialogStore } from '../../store/dialogStore';
  import { mCreditStore } from '../../store/mCreditStore';
  import { settingsStore } from '../../store/settingsStore';
  import { topBar } from '../../store/TopBarStore';
  import {
    CreditSettleFrequency,
    DateRange,
    ERR_429,
    MCREDIT_CODE,
    MPayApi,
  } from '../constants';
  import { Context } from '../utils/analytics/keys';
  import { groupInvoices } from '../utils/creditFrequency';
  import { calculateDateRange, formatDate } from '../utils/date';
  import { sendRequest } from '../utils/fetch';
  import {
    addTypeToInvoices,
    getMergedTransactionsData,
    getTransactions,
  } from '../utils/invoices';
  import {
    EventsMapKeys,
    passBackendTracking,
    trackEvents,
  } from '../utils/tracking';
  import EmptyScreen from './EmptyScreen.svelte';
  import Icon from './Icon.svelte';
  import InvoicesList from './InvoicesList.svelte';
  import InvoicesPlaceholder from './InvoicesPlaceholder.svelte';
  import LinkButton from './LinkButton.svelte';

  let isDataLoading = false;
  let invoicesError: null | unknown = null;
  let groupBy: CreditSettleFrequency;
  let latestInvoiceDate: null | string = null;

  onMount(() => {
    // TODO: a more sophisticated and thorough check if to load new invoices or not
    if (Object.keys($mCreditStore.transactions).length > 0) {
      return;
    }
    fetchInvoices();
  });

  const fetchMoreInvoices = () => {
    if ($mCreditStore.pagination.totalPages <= $mCreditStore.currentPage) {
      return;
    }
    trackEvents(EventsMapKeys.LOAD_MORE_INVOICES, {
      category: $topBar.context,
      requestedPage: $mCreditStore.currentPage + 1,
    });
    mCreditStore.setCurrentPage($mCreditStore.currentPage + 1);
    fetchInvoices(true);

    setTimeout(() => {
      window.scrollBy({
        behavior: 'smooth',
        left: 0,
        top: window.innerHeight - 150,
      });
    }, 100);
  };

  function constructUrlWithParams() {
    const baseUrl = `${MPAY_API_BASE_URI}/${MPayApi.INVOICES}/${$settingsStore.credentials.accountId}`;
    const { endDate, startDate } = calculateDateRange(
      DateRange.THIS_AND_LAST_YEAR
    );

    const queryParams = new URLSearchParams({
      applyPODMapping: 'true', // excludes COD invoices which should not be shown in MCredit tab
      from: formatDate(startDate),
      limit: '100',
      page: `${$mCreditStore.currentPage}`,
      'paymentCode[]': MCREDIT_CODE,
      to: formatDate(endDate),
    }).toString();

    return `${baseUrl}?${queryParams}`;
  }

  const mergedInvoiceData = (data: Transactions) => {
    const newData = groupInvoices(data, groupBy);
    const storedData = $mCreditStore.transactions;

    return getMergedTransactionsData(storedData, newData);
  };

  function getPaymentDateRange(
    invoices: Invoice[],
    fetchNextPage: boolean,
    latestInvoiceDate: null | string
  ) {
    const currentPageLastDate =
      invoices.length > 0
        ? formatDate(new Date(invoices[invoices.length - 1].date))
        : null;

    return {
      from: currentPageLastDate ?? latestInvoiceDate,
      to: fetchNextPage ? latestInvoiceDate : null,
    };
  }

  function constructPaymentsUrl(
    dates: { from: null | string; to: null | string },
    accountId: string
  ) {
    const params = new URLSearchParams();
    if (dates.from) params.append('from', dates.from);
    if (dates.to) params.append('to', dates.to);

    return `${MPAY_API_BASE_URI}/${MPayApi.PAYMENTS}/${accountId}?${params}`;
  }

  async function fetchInvoices(fetchNextPage = false) {
    isDataLoading = true;
    invoicesError = null;

    try {
      const response = await sendRequest<{
        _pagination: Pagination;
        invoices: Invoice[];
      }>(constructUrlWithParams());

      const dateRange = getPaymentDateRange(
        response.invoices,
        fetchNextPage,
        latestInvoiceDate
      );
      const payments = await sendRequest<Repayment[]>(
        constructPaymentsUrl(dateRange, $settingsStore.credentials.accountId)
      );

      // Update latestInvoiceDate for next page
      latestInvoiceDate =
        response.invoices.length > 0
          ? formatDate(
              new Date(response.invoices[response.invoices.length - 1].date)
            )
          : null;

      if (!fetchNextPage) {
        groupBy = $mCreditStore.creditSettle.creditTpContract;
      }

      // merge invoices and payments together
      const transactions = getTransactions(response.invoices, payments);
      passBackendTracking('responseInvoiceCount', {
        tab: Context.Mcredit,
        value: transactions.length,
      });

      mCreditStore.setTransactions({
        pagination: response._pagination,
        transactions: fetchNextPage
          ? mergedInvoiceData(addTypeToInvoices(response.invoices))
          : groupInvoices(transactions, groupBy),
      });
    } catch (err) {
      invoicesError = err;
      if (fetchNextPage) {
        mCreditStore.setCurrentPage($mCreditStore.currentPage - 1);
      }

      if (
        invoicesError!.toString() !== ERR_429 ||
        !invoicesError!.toString().includes(ERR_429)
      ) {
        dialogStore.setRetryAction(fetchInvoices);
      }
    } finally {
      isDataLoading = false;
    }
  }
</script>

<section class="pt-6" data-cy="mcredit-invoices">
  {#if !isDataLoading && !invoicesError && Object.keys($mCreditStore.transactions).length === 0}
    <EmptyScreen label={$t('invoices.no_invoices')} />
  {:else}
    <div
      class="mx-5 mb-4 flex items-center gap-3 rounded-2xl bg-blue-tint90 px-3 py-4 text-blue-base"
    >
      <div class="flex-shrink-0">
        <Icon iconName="AlertInfo" size="16" />
      </div>
      <p class="text-android-body-small ios:text-ios-footnote">
        {$t('mcredit.banner')}
      </p>
    </div>
    {#each Object.keys($mCreditStore.transactions) as timePeriod, index}
      <InvoicesList
        id="mcredit-invoices-list"
        {timePeriod}
        {index}
        transactions={$mCreditStore.transactions}
        isMcredit
      />
    {/each}

    {#if $mCreditStore.pagination.totalPages > $mCreditStore.currentPage}
      <div class="flex justify-center">
        <LinkButton
          id="load-more-mcredit-invoices"
          isDisabled={isDataLoading}
          onClick={fetchMoreInvoices}
          label={$t('general.cta.load_more')}
        />
      </div>
    {/if}
  {/if}

  {#if isDataLoading}
    <InvoicesPlaceholder />
  {/if}
</section>
